@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .canvas-container {
    width: 800px;
    height: 600px;
  }
}

@media screen and (min-width: 892px) and (max-width: 1023px) {
  .canvas-container {
    width: 620px;
    height: 500px;
  }
}

@media screen and (max-width: 891px) {
  .canvas-container {
    width: 500px;
    height: 400px;
  }
}

@media screen and (min-width: 1366px) and (max-width: 2600px) {
  .canvas-container {
    width: 800px;
    height: 600px;
  }
}

