.edit-form-shadow {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.input-class-edit {
  margin-bottom: 25px;
  border-width: 0 0 1px 0;
  border-color: rgba(0, 0, 0, 0.12);
  line-height: 26px;
  transition: border-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.label-class-edit {
  color: rgba(0, 0, 0, 0.54);
  transition: 0.3s ease-in-out;
}

.input-class-edit,
.label-class-edit {
  font-size: 1rem;
  width: 100%;
}

.input-class-edit:focus + .label-class-edit,
.input-class-edit:valid + .label-class-edit {
  color: #3e8ede;
  font-size: 0.8rem;
  top: -25px;
  pointer-events: none;
}

.input-class-edit:focus,
.input-class-edit:valid {
  border-color: #3e8ede;
  border-width: 0 0 2px 0;
}

.mt-23 {
  margin-top: -23px;
}

.error-input-class-edit {
  border-color: rgb(221, 44, 0);
}

.error-label-class-edit {
  color: rgb(221, 44, 0);
}

.top-25 {
  font-size: 0.8rem;
  top: -25px;
}
