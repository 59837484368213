@media screen and (min-width: 768px) and (max-width: 1024px) {
    .partition-line{
        padding: 2px;
        margin: 0px;
    }
   
    .module-sets-box{
        padding: 0px;
        margin-left: 2px;
    }
    .module-box-row{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .module-box-items{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
    }

    .resources-sets-box{
        padding: 0px;
    }
    .resources-box-items{
        font-size: 14px;
    }
}
  