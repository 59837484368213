.rdw-image-imagewrapper img {
  width: 100%;
}

.custom-modal-link-class + .rdw-link-modal {
  margin-top: 5px !important;
  left: -220px !important;
}

.custom-html-content-class .public-DraftStyleDefault-block {
  margin: 0em 0em;
}
