.footer-bar-container {
  max-width: 1040px;
  margin: 0 auto;
}

.divider {
  background-color: rgb(117, 117, 117);
  color: rgb(255, 255, 255);
}

.divider-clr {
  border-color: rgba(0, 0, 0, 0.12);
  border-top-style: solid;
}

.footer-text-cont-left {
  flex: 1 1 100%;
  max-width: 66.66%;
}

.footer-text-cont-right {
  flex: 1 1 100%;
  max-width: 33.33%;
}

.text-clr {
  color: rgb(117, 117, 117);
}
