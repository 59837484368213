.pagination-text {
  color: rgb(158, 158, 158);
  min-width: 0;
  padding-right: 8px;
  line-height: 24px;
  margin-bottom: 2px;
}

.form_title {
  color: rgb(117, 117, 117);
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0.005em;
}

.search-input {
  border: 0;
  order: 2;
  outline: none;
  margin-top: 0;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-left: 0;
  padding-right: 2px;
  border-width: 0 0 1px 0;
  border-color: rgba(0, 0, 0, 0.12);
  line-height: 26px;
  height: 20px;
  border-radius: 0;
  border-style: solid;
  transition: border-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-sizing: border-box;
}

.search-input:focus {
  pointer-events: none;
  border-color: rgb(74, 61, 115);
}