@tailwind preflight;

@tailwind components;

@tailwind utilities;

/* Custom css */

html,
body {
  width: 100%;
  margin: 0px;
  font-family: "Lato", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0");

.bg-primary {
  background-color: rgb(74, 61, 115);
}

.text-white-87 {
  color: rgba(255, 255, 255, 0.87);
}

.text-black-87 {
  color: rgba(0, 0, 0, 0.87);
}

.text-gray-38 {
  color: rgba(0, 0, 0, 0.5);
}

.text-black-117 {
  color: rgb(117, 117, 117);
}

.font-21px {
  font-size: 21px;
}

.letter-spacing-005 {
  letter-spacing: 0.005em;
}

.w-30 {
  min-width: 120px !important;
}

.w-40 {
  min-width: 160px !important;
}

.bg-white-250 {
  background-color: rgba(250, 250, 250);
}

.w-webkit {
  width: -webkit-fill-available;
}

.h-webkit {
  height: -webkit-fill-available;
}

.h-full-window {
  height: 100vh;
}

.default-button {
  min-height: 36px;
  min-width: 88px;
  line-height: 36px;
}

.btn-shadow {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
}

.header-profile-page {
  background-color: #faf7f7;
  width: 100%;
  height: 100vh;
}

.edit-profile-text {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.text-white-side-nav {
  color: white !important;
}

.default-linked-button {
  min-height: 36px;
  min-width: 88px;
  /* line-height: 34px; */
  background: none;
}

.text-primary-115 {
  color: rgb(74, 61, 115);
}

.default-linked-button:hover {
  background-color: rgba(158, 158, 158, 0.2);
  transition: 0.3s ease-in-out;
}

.bg-gray-236 {
  background-color: #ececec;
}

.partition-line {
  border-right: 2px solid rgb(209 213 219);
  height: calc(78vh - 3px);
}

.height-75 {
  height: calc(60vh - 3px);
}

.height-65 {
  height: calc(62vh - 3px);
}

.bg-red-203 {
  background-color: rgba(203, 51, 59, 0.15);
}

.bg-teal-62 {
  background-color: rgba(62, 142, 222, 0.21);
}

.bg-blue-80 {
  background-color: rgba(80, 7, 120, 0.21);
}

.bg-purple-80 {
  background-color: rgba(80, 7, 120, 0.15);
}

.bg-pink-203 {
  background-color: rgba(203, 51, 59, 0.17);
}

.bg-orange-243 {
  background-color: rgba(243, 138, 0, 0.27);
}

.bg-blue-99 {
  background-color: rgba(99, 178, 245, 0.3);
}

.bg-green-98 {
  background-color: rgba(98, 166, 10, 0.29);
}

.resources-row-box-shadow {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.mx-imp {
  margin-left: 2px !important;
  margin-right: 2px !important;
}
.text-blue-theme {
  color: #3e8ede;
}

.border-blue-theme {
  border-color: #3e8ede;
}

.text-box-border {
  border-color: #ccc !important;
}

.text-ccc {
  color: #ccc;
}

.min-hieght-300 {
  min-height: 300px;
}

.hieght-60 {
  height: 60px !important;
}

.min-hieght-600 {
  min-height: 600px;
}

.m-0-imp {
  margin: 0px !important;
}

.border-0-imp {
  border: 0px !important;
}

.border-left-0-imp {
  border-left: 0px !important;
}

.border-right-0-imp {
  border-right: 0px !important;
}

.padding-0-imp {
  padding: 0px !important;
}

.mx-left-6px-imp {
  margin-left: 6px !important;
}

.mr-0px-imp {
  margin-right: 0px !important;
}

.md-card {
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 2px 1px -1px rgb(0 0 0 / 12%);
}

.nav-tabs::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.custom-flex {
  flex-grow: 1;
}

/* Only for dynamic module categories dropdown inside assign modules - manage session */
@media (min-width: 768px) {
  .custom-width {
    width: 47%;
  }
}

.max-width-40-percent {
  max-width: 40%;
}

.max-width-20-percent {
  max-width: 20%;
}

.max-width-35-percent {
  max-width: 35%;
}

.max-width-30-percent {
  max-width: 30%;
}
