.login-button {
  // background-color: rgb(74, 61, 115);
  color: rgba(255, 255, 255, 0.87);
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  cursor: pointer;
  min-height: 36px;
  min-width: 88px;
  line-height: 36px;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  user-select: none;
  outline: none;
  border: 0;
  padding: 0 20px;
  margin: 6px 8px;
}

.icn-login {
  margin: auto;
  display: inline-block;
  vertical-align: middle;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
  margin-right: 4px;
}

.floating-button {
  max-height: calc(100vh - 10px);
  position: relative;
  z-index: 1;
  border-radius: 2px;
  overflow: auto;
  top: 0px;
  left: 0px;
  transform-origin: left top;
  opacity: 1;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 200ms;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  padding: 8px 0;
  max-height: 304px;
  overflow-y: auto;
  background-color: rgb(255, 255, 255);
  min-width: 210px;
}

.floating-btn-menu {
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  // flex-direction: row;
  min-height: 48px;
  height: 48px;
  align-content: center;
  justify-content: flex-start;
}

.btn-css {
  position: relative;
  text-align: left;
  // display: inline-block;
  // border-radius: 0;
  // margin: auto 0;
  font-size: 15px;
  // text-transform: none;
  font-weight: 400;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  cursor: pointer;
  line-height: 36px;
  vertical-align: middle;
  box-sizing: border-box;
  user-select: none;
  outline: none;
  border: 0;
  /* padding: 0 8px; */
  background: transparent;
  color: currentColor;
  white-space: nowrap;
  text-decoration: none;
  overflow: hidden;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.icon-margin {
  margin: auto 16px auto 0;
}
