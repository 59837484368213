.polygon-bg-style {
  background-image: url("../images/img_polygons_row.svg"),
    linear-gradient(to right, rgb(54, 196, 205) 0%, rgb(51, 158, 251) 100%);
}

.hero-img {
  background-position: center center, center center;
  background-repeat: repeat-x;
  box-shadow: 0px 8px 11px -9px #000 inset;
}

.landing-page-container {
  max-width: 1040px;
  margin: 0 auto;
}

.landing-page-sub-container {
  max-width: 600px;
  margin: 0 auto;
}

.bio-text {
  font-size: 45px;
  line-height: 64px;
}

.mb-30 {
  margin-bottom: 30px;
}

.letter-spacing-005 {
  letter-spacing: 0.005rem;
}

.mb-50 {
  margin-bottom: 50px;
}

.bioscannbluetheme-img {
  background: url("../images/img_contactushome.png") right bottom no-repeat;
}

.form-cont-left {
  flex: 1 1 100%;
  max-width: 60%;
}

.form-shadow {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.field-holder {
  position: relative;
  padding: 2px;
  margin: 18px 0px;
  vertical-align: middle;
}

.input-class,
.select-class {
  border: 0;
  order: 2;
  outline: none;
  margin-top: 0;
  margin-bottom: 25px;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-left: 0;
  padding-right: 2px;
  border-width: 0 0 1px 0;
  border-color: rgba(0, 0, 0, 0.12);
  line-height: 26px;
  height: 20px;
  border-radius: 0;
  border-style: solid;
  transition: border-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-sizing: border-box;
}

.input-class {
  height: 26px !important;
}

.select-class {
  cursor: pointer;
  background: none;
  width: 100%;
  font-size: 1rem;
  padding-top: 0;
  padding-bottom: 0;
}

.select-class option {
  background: #fff;
  border: none;
  width: 100%;
  position: absolute;
  background-color: #f9f9f9;
  // min-width: 160px;
  padding: 12px 16px;
  z-index: 1;
}

.select-class:focus + .select-label-class,
.select-class:valid + .select-label-class {
  color: rgb(62, 142, 222);
  font-size: 0.8rem;
  top: -25px;
  pointer-events: none;
}

.select-label-class {
  position: absolute;
  top: 0;
  left: 0;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.label-class {
  position: absolute;
  top: 0;
  left: 0;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  align-items: center;
  cursor: text;
  transition: 0.3s ease-in-out;
}

.input-class,
.label-class,
.select-label-class .select-class {
  font-size: 1rem;
  width: 100%;
}

.input-class:focus + .label-class,
.input-class:valid + .label-class {
  color: rgb(62, 142, 222);
  font-size: 0.8rem;
  top: -25px;
  pointer-events: none;
}

.input-class:focus,
.input-class:valid,
.select-class:focus {
  border-color: rgb(62, 142, 222);
}

.checkbox-style {
  width: 24px;
  height: 48px;
  color: white;
  // border-color: rgba(0, 0, 0, 0.54);
  accent-color: rgb(62, 142, 222);
  background-color: white;
}

.submit-button {
  min-height: 36px;
  min-width: 88px;
  line-height: 36px;
  border-radius: 2px;
  user-select: none;
  outline: none;
  padding: 0 8px;
  margin: 8px 8px;
  background: none;
  border: 0.1px solid rgb(62, 142, 222);
  font-weight: 500;
  font-size: 14px;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  color: rgb(62, 142, 222);
  margin-right: 160px;
}

.container-check {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 20px;
  border: 2px solid rgba(0, 0, 0, 0.54);
}

/* When the checkbox is checked, add a blue background */
.container-check input:checked ~ .checkmark {
  background-color: rgb(62, 142, 222);
  border: none;
  outline: none;
  width: 24px;
  height: 24px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  border: 1px solid red;
}

/* Show the checkmark when checked */
.container-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
  left: 8px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.login-button-without-icon {
  min-height: 36px;
  min-width: 88px;
  line-height: 36px;
}

.box-shadow-btn {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
}

.input-class-login {
  margin-bottom: 25px;
  border-width: 0 0 1px 0;
  border-color: rgba(0, 0, 0, 0.12);
  line-height: 26px;
  transition: border-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-sizing: border-box;
}

.label-class-login {
  color: rgba(0, 0, 0, 0.54);
  transition: 0.3s ease-in-out;
}

.input-class-login,
.label-class-login {
  font-size: 1rem;
  width: 100%;
}

.input-class-login:focus + .label-class-login,
.input-class-login:valid + .label-class-login {
  color: #3e8ede;
  font-size: 0.8rem;
  top: -25px;
  pointer-events: none;
}

.input-class-login:focus,
.input-class-login:valid {
  border-color: #3e8ede;
  border-width: 0 0 2px 0;
}

.mt-25 {
  margin-top: -23px;
  font-size: 14px;
}

.error-input-class {
  border-color: rgb(221, 44, 0) !important;
}

.error-label-class {
  color: rgb(221, 44, 0) !important;
}

.error-select-class {
  border-color: rgb(221, 44, 0);
}

.error-select-label-class {
  color: rgb(221, 44, 0);
}

.text-area-input {
  border-width: 0 0 1px 0;
  border-color: rgba(0, 0, 0, 0.12);
  line-height: 26px;
  transition: border-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-sizing: border-box;
}

.text-area-label {
  color: rgba(0, 0, 0, 0.54);
  transition: 0.3s ease-in-out;
}

.text-area-input,
.text-area-label {
  font-size: 1rem;
  width: 100%;
}

.text-area-input:focus + .text-area-label,
.text-area-input:valid + .text-area-label {
  color: rgb(62, 142, 222);
  font-size: 0.8rem;
  top: -15px;
  pointer-events: none;
}

.text-area-input:focus,
.text-area-input:valid {
  border-color: rgb(62, 142, 222);
  border-width: 0 0 2px 0;
}
