.rdw-fontfamily-wrapper .rdw-dropdown-wrapper .rdw-fontfamily-optionwrapper{
    width: 200px;
    margin-top: 3px !important;
    height: auto !important;
    overflow-y: auto !important;
    padding: 8px 0 !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.rdw-fontfamily-wrapper .rdw-dropdown-wrapper .rdw-dropdownoption-default{
    margin: 10px;
    color: rgba(0, 0, 0, 0.87);
}

.rdw-fontfamily-wrapper .rdw-dropdown-wrapper .rdw-dropdown-optionwrapper li {
    font-family: Arial, sans-serif;
    font-size: 16px;
}
  
.rdw-fontfamily-wrapper .rdw-dropdown-wrapper .rdw-dropdownoption-default:nth-child(1) {
    font-family: 'Lato', sans-serif;
}
  
.rdw-fontfamily-wrapper .rdw-dropdown-wrapper .rdw-dropdownoption-default:nth-child(2) {
    font-family: 'Gloria Hallelujah', cursive;
}
  
.rdw-fontfamily-wrapper .rdw-dropdown-wrapper .rdw-dropdownoption-default:nth-child(3) {
    font-family: 'Cedarville Cursive', cursive;
}
  
.rdw-fontfamily-wrapper .rdw-dropdown-wrapper .rdw-dropdownoption-default:nth-child(4) {
    font-family: 'Rock Salt', cursive;
}
  
.rdw-fontfamily-wrapper .rdw-dropdown-wrapper .rdw-dropdownoption-default:nth-child(5) {
    font-family: 'Swanky and Moo Moo', cursive;
}
  

//font size
.rdw-fontsize-wrapper .rdw-dropdown-wrapper .rdw-dropdown-optionwrapper{
    margin-top: 3px;
    max-height: 170px !important;
}