.canvas-resources {
  width: 100%;
  height: 600px;
}

.child-canvas {
  transform: translate(-50%, -50%);
}

.textrich-button {
  min-height: 36px;
  min-width: 88px;
  background: none;
  border: 0.1px solid rgb(62, 142, 222);
  color: rgb(62, 142, 222);
}

.upload-zoom-btn:hover,
.textrich-button:hover {
  background-color: rgba(158, 158, 158, 0.2);
  transition: 0.3s ease-in-out;
}

