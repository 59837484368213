.landing-page-header {
  background-color: rgb(238, 238, 238);
  position: relative;
  z-index: 2;
  width: 100%;
  box-shadow: 0px 4px 5px 0px rgb(0 0 0 / 10%) !important;
}

.nav-bar {
  box-sizing: border-box;
  display: flex;
  font-size: 20px;
  min-height: 64px;
  width: 100%;
  align-items: center;
  flex-direction: row;
  height: 64px;
  max-height: 64px;
  padding: 0 16px;
}

.nav-bar-container {
  max-width: 1040px;
  margin: 0 auto;
}

.flex-span {
  flex: 1;
}

.divider-clr-section {
  border-color: rgba(0, 0, 0, 0.12);
  border-top-style: solid;
}
