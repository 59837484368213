.custom-modal-class {
  background: none !important;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 5px;
  width: 28%;
}

.custom-login-modal-class {
  background: none !important;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 5px;
  width: 45%;
}

.custom-edit-modal-class {
  background: none !important;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 5px;
  width: 100%;
  overflow-y: initial;
}

.custom-filter-modal-class {
  background: none !important;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 5px;
  width: 28%;
  overflow-y: initial;
}
  
.edit-dropdown-modal .css-1w8emqp-MenuList{height: 150px !important;}
.edit-dropdown-modal .css-18ujmoq-MenuList{height: 150px !important;}
.edit-dropdown-modal .css-119a26u-MenuList{height: 150px !important;}
.edit-dropdown-modal .css-1n6sfyn-MenuList{height: 150px !important;}