.timeline{padding: 0px !important;display: flex;justify-content: space-between;margin-top: 40px;position: relative;margin: auto; width: 100%;max-width: 900px;border-color: rgb(212 212 212);}
.timeline::after{content: "";position: absolute;width: 100%;height: 2px;background-color: #ccc;bottom: 7px;left:0;margin: 0 auto;right:0;overflow-x: hidden}
.timeline li{z-index: 11;position: relative;padding-bottom: 40px;display: flex;align-items: flex-end;}
.timeline li:after{content:'';position: absolute;bottom: 0px;right: 0px;left: 0px;height: 16px; width: 16px; background-color: #fff;border-radius: 50%;margin: auto;border: 2px solid #3e8ede;}

.timeline-title{margin-top: 10px;display: flex;align-items: flex-end;text-align: left;bottom: 10px;width: 77px;position: relative;left: 30px;transform: rotate(315deg);}
.timeline-title p{font-size: 12px;line-height: 16px;color: rgba(0, 0, 0,0.87);margin: 0;}
.timeline-title p.text-active{font-size: 14px;line-height: 16px;color: rgba(0,0,0);font-weight: bolder;}

.timeline li.active:after{background-color: #3e8ede;bottom: -3px;height: 22px;width: 22px;}
.timeline li.active-stop:after{position: relative;height: 22px;width: 22px;top: 60px;background-color: #ff0000;}

.timeline-buttons{display: flex;justify-content: space-between;margin: auto;max-width: 1000px;margin-top: 20px;padding: 0px !important;}
.timeline-buttons button{font-size: 14px;line-height: 36px;font-weight: 500;color: #fff;text-align: center;border: 0px;cursor: pointer;min-height: 36px;min-width: 88px;background-color:#3e8ede;padding: 0 8px;margin: 6px 8px;box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);}

.timeline li.circle::after{background-color: #ff0000 !important;border-color: #ff0000 !important;width: 22px;height: 22px;bottom: -3px;}
.timeline li.circle::before {content: "";position: absolute;bottom: 4px;left: 0;width: 11px;height: 11px;background-color: white;z-index: 11;right: 0;margin: 0 auto;}

.layout-container{max-width: 1000px;}
.layout-container .layout{margin: 0 auto;padding: 20px;}
.layout div img,.layout p img{width: 100%;}

.no-details-message{display: flex;justify-content: center;align-items: center;margin-top: 40px;color: rgba(0, 0, 0, 0.67);}

@media screen and (max-width: 1199px) {
  .timeline {
    max-width: 750px;
  }
}
  
@media screen and (max-width: 1023px) {
  .timeline {
    max-width: 650px;
  }
}


.timeline li.prev-node:after {
  background-color: #85bef7;
  border: 2px solid #85bef7;
  bottom: -2px;
}