.header {
  width: 100%;
  height: 100vh;
}

.nav-divider {
  border-bottom: 1px solid white;
  width: 95%;
}

.nav-bar-side {
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  width: 180px;
}
.nav-bar-side .logo-side {
  margin: 0 10px;
}

.logo-side .menu-icon-side {
  cursor: pointer;
}

.nav-bar-side .sidebar {
  position: fixed;
  top: 0;
  left: 0%;
  height: 100%;
  width: 210px;
  padding: 20px 0;
  background-color: #3e8ede;
  box-shadow: 0 5px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
}

.sidebar .sidebar-content {
  display: flex;
  height: 80%;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 3px;
}
.sidebar-content .list {
  list-style: none;
}
.lists {
  padding-inline-start: 0px;
}
.list .nav-link-side {
  display: flex;
  align-items: center;
  margin: 8px 0;
  padding: 14px 0px;
  border-radius: 4px;
  text-decoration: none;
}
.lists .nav-link-side:hover {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.nav-link-side .icon-side {
  margin-right: 14px;
  margin-left: 30px;
  font-size: 20px;
}
.nav-link-side .link {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
}
.lists .nav-link-side:hover .icon-side,
.lists .nav-link-side:hover .link {
  color: #63b2f5 !important;
  font-weight: 700;
}

.active-nav-side-link {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.active-link {
  color: #63b2f5 !important;
  font-weight: 700 !important;
}


