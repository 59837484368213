.box {width: 500px;}
.box .image-box{width: 60px;}
.image-box img{width: 30px;}
.box .text-box{
  width: calc(100% - 60px);
}

  
/* for devices with width up to 600px */
@media (max-width: 600px) {
    .group-box {
      margin: 5px;
      padding: 5px;
    }
    .module-container {
      margin: 10px 0;
    }
    .box {
      width: 100%;
    }
    .box p {
      font-size: 10px;
      line-height: 8px;
    }
  }
  
  /* for devices with width between 600px and 900px */
  @media (min-width: 601px) and (max-width: 900px) {
    .module-container {
      flex-direction: column;
      flex-wrap: wrap;
    }
    .box {
      width: 300px;
    }
    .image-box img{
      width: 32px;
    }
    .box p {
      font-size: 12px;
      line-height: 10px;
    }
  }